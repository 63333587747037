import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TV from "../../../components/body/pages/bill-payments/tv"

const TvPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/bill-payments/tv"}
      title="TV Packages & Subscriptions | Bill Payments | Kuda"
      description="Simplify TV subscription payments ensuring uninterrupted access to top-notch entertainment. Pay for GOtv, DStv & more. Download the Kuda app now!"
    />
    <TV />
  </Layout>
)

export default TvPage
