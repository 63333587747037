import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import Illustration from "../../../../assets/img/tv-entry-illustration.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import NairaIcon from "../../../../assets/img/naira.inline.svg"
import KudaAppIcon from "../../../../assets/img/kuda-app-miniIcon.inline.svg"
import TelevisonIcon from "../../../../assets/img/television-icon.inline.svg"
import TvProviderImage from "../../../../assets/img/choose-tv-provider.inline.svg"
import TVPackageImage from "../../../../assets/img/choose-tv-package.inline.svg"
import KudaAppSectionImage from "../../../../assets/img/k-transaction.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import MoreForYouComponent from "../general/more-for-you";

import KudaAirtimeIcon from '../../../../assets/img/more-for-you/kuda-airtime-icon.inline.svg'
import KudaInternetIcon from '../../../../assets/img/more-for-you/kuda-internet-icon.inline.svg'
import BorrowIcon from "../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg"

const entryContent = {
    title: "Pay your TV subscriptions easily on the Kuda app!",
    subtitle: "Kuda secures your entertainment with convenient DStv, Showmax, GOtv and StarTimes payments.",
    illustration: <Illustration />
}
const kudaTopFeatures = [
    {
        icon: <NairaIcon />,
        text: "Pay DStv, Showmax, GOtv and StarTimes subscriptions easily.",
    },
    {
        icon: <KudaAppIcon />,
        text: "Get your subscription token on the Kuda app or in your inbox.",
    },
    {
        icon: <TelevisonIcon />,
        text: "Never switch apps to make your TV payments again.",
    },
]
const firstSection = {
    title: "Find all major TV service providers.",
    subtitle: "DStv, Showmax, GOtv and StarTimes are all easy-to-find options for you on the Kuda app.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <TvProviderImage />,
}
const secondSection = {
    title: "It takes just minutes to pay your TV subscription.",
    subtitle: "Choose, pay and watch. It’s that simple to sort out your TV subscription on the Kuda app.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <KudaAppSectionImage />,
}
const thirdSection = {
    title: "Find all popular TV subscription packages.",
    subtitle: "Whether you have a favourite TV subscription package or you’re looking for the right one, the Kuda app makes it easy.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <TVPackageImage />,
}

const questions = [
    {
        heading: "What TV subscriptions can I pay on the Kuda app?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">You can pay DStv subscriptions, Showmax subscriptions, GOtv subscriptions and StarTimes subscriptions on the Kuda app.</span>
            </span>),
        index: 1
    },
    {
        heading: "How do I get my token when I pay a Showmax subscription on the Kuda app?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">
                    Tap the Showmax payment in your transaction history on the Kuda app to get your token. You can also check your email.
                </span>
            </span>),
        index: 2
    },
    {
        heading: "How do I pay a TV subscription on the Kuda app?",
        list: (
            <span className="faq-description f-16 flex flex-column">
                <span>To pay a DStv, Showmax, GOtv or StarTimes subscription on the Kuda app, follow the steps below:</span>
                <span className="faq-description f-16 pt-3 career-message-margin-bottom ">
                    <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16 mx-1">
                        <li className="mb-3">Sign in to your Kuda app, then tap <b>Pay</b>.</li>
                        <li className="mb-3">Tap <b>Pay A Bill</b>.</li>
                        <li className="mb-3">Tap <b>TV</b>.</li>
                        <li className="mb-3">Tap the service provider dropdown menu.</li>
                        <li className="mb-3">Choose a service provider.</li>
                        <li className="mb-3">Tap the subscription package dropdown menu.</li>
                        <li className="mb-3">Choose a subscription package.</li>
                        <li className="mb-3">If you're paying a DStv, GOtv or Startimes subscription, type in your smart card number.</li>
                        <li className="mb-3">Confirm your subscription with your transaction PIN, fingerprint or Face ID. </li>
                        <li className="mb-3">Tap <b>Okay</b> to return to your Kuda dashboard.</li>
                    </ol>
                    <div className="mb-3">Note:</div>
                    <p className="mb-3">If you pay a Showmax subscription, tap the payment in your transaction history on the Kuda app to get your token or check your email.</p>
                    <p>If you pay a DStv, GOtv or StarTimes subscription, your subscription should be active in minutes.</p>
                </span>
            </span>),
        index: 3
    }
]
const moreForYou = [
    {
        icon: <KudaInternetIcon />,
        title: "Internet",
        subText: `Buy internet data from major Nigerian internet service providers easily on your Kuda app.`,
        linkTo: `/en-ng/bill-payments/internet/`
    },
    {
        icon: <KudaAirtimeIcon />,
        title: "Airtime",
        subText: `Buy Airtel, Glo, MTN or 9Mobile airtime directly from your account on your Kuda app.`,
        linkTo: `/en-ng/airtime/`
    },
    {
        icon: <BorrowIcon />,
        title: "Loans",
        subText: `Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.`,
        linkTo: `/en-ng/personal-loan/`
    },

]
const TV = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={firstSection.title}
                subtitle={firstSection.subtitle}
                illustration={firstSection.illustration}
                name={firstSection.name}
                url={firstSection.url}
            />
            <ImageTextLeft
                title={secondSection.title}
                subtitle={secondSection.subtitle}
                illustration={secondSection.illustration}
                name={secondSection.name}
                url={secondSection.url}
            />
            <ImageTextRight
                title={thirdSection.title}
                subtitle={thirdSection.subtitle}
                illustration={thirdSection.illustration}
                name={thirdSection.name}
                url={thirdSection.url}
            />
            <FAQList title={"TV Subscription FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou} />
            <CTA />
        </Fragment>
    )
}

export default TV;
